import * as React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"

const seoTitle = "プライバシーポリシー｜ TANOSHIGE【たのしげ】"
const seoDescription = "「TANOSHIGE」サイトで取り扱う個人情報をにおいて「個人情報の保護に関する法律」をはじめとする個人情報の保護に関する法令、ガイドラインおよび本プライバシーポリシーを遵守いたします。"

// markup
const PrivacyPage = () => {
  return (
    <Layout isHomePage={false} headerClass="bg-white" isLogoOneWhite={false} isLogoTwoWhite={false}>
      <Seo title={seoTitle} description={seoDescription} />
      <main id="privacy">
        <h2><StaticImage src="../images/ttl-privacy@2x.png" alt="プライバシーポリシー" /></h2>
        <section className="inner">
          <h2>個人情報の管理</h2>
          <p>個人情報を取り扱うにあたっては、「個人情報の保護に関する法律」をはじめとする個人情報の保護に関する法令、ガイドラインおよび本プライバシーポリシーを遵守いたします。</p>

          <h2>個人情報の利用目的</h2>
          <p>
            TANOSHIGE（以下、「当サイト」とします）では、訪問者様からのお問い合わせやコメントをお受けした際に、お名前（ハンドルネーム）、メールアドレス等の個人情報をご登録いただく場合がございます。<br></br>
              これらの個人情報は質問に対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
          </p>

          <h2>個人情報の第三者への開示</h2>
          <p>当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。</p>
          <ul>
            <li>本人のご了解がある場合</li>
            <li>法令等への協力のため、開示が必要となる場合</li>
          </ul>
          <h2>個人情報の開示、訂正、追加、削除、利用停止</h2>
          <p>
            ご本人からの個人データの開示、訂正、追加、削除のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
          </p>

          <h2>Cookie（クッキー）</h2>
          <p>
            当サイトでは、一部のコンテンツについて情報の収集にCookieを使用しています。<br></br>
              Cookieは、ユーザーがサイトを訪れた際に、そのユーザーのコンピュータ内に記録されます。<br></br>
              ただし、記録される情報には、ユーザー名やメールアドレスなど、個人を特定するものは一切含まれません。<br></br>
              また、当サイトではユーザーの方々がどのようなサービスに興味をお持ちなのかを分析したり、ウェブ上での効果的な広告の配信のためにこれらを利用させていただく場合があります。
          </p>

          <h2>当サイトが使用しているアクセス解析ツールについて</h2>
          <p>
            当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br></br>
              このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくはこちらをご参照ください。
          </p>

          <h2>著作権について</h2>
          <p>
            当サイトに掲載されている情報についての著作権は放棄しておりません。<br></br>
              著作権法により認められている引用の範囲である場合を除き「内容、テキスト、画像等」の無断転載・使用を固く禁じます。
          </p>

          <h2>免責事項</h2>
          <p>
            当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。<br></br>
              当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。<br></br>
              当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
          </p>

          <h2>プライバシーポリシーの変更について</h2>
          <p>
            当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
            修正された最新のプライバシーポリシーは常に本ページにて開示されます。
          </p>

          <h2>お問い合わせ</h2>
          <p>当サイトへの、お問い合わせに関しては、<Link to="/contact">https://tanoshige.jp/contact</Link>からどうぞ。</p>
        </section>
      </main>

    </Layout>
  )
}

export default PrivacyPage
